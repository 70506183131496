<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="content__html" @click="handleLinkClick" v-html="content" />
</template>

<script>

import handleLinkClick from '@/mixins/handle-link-click-mixin';

export default {
  name: 'ContentHtml',
  mixins: [handleLinkClick],
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<docs>
This component is basically a wrapper around v-html that allows HTML content from
wordpress to be dynamically rendered by the ContentRenderer. Note that this component
cannot have children as there is no way to specify a slot inside of v-html.

</docs>
