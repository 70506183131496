const nearestLinkToTarget = (target) => {
  let elementToCheck = target;

  while (elementToCheck && elementToCheck.tagName !== 'A') elementToCheck = elementToCheck.parentNode;

  return elementToCheck;
};

const targetIsALink = (target) => target && target.href;

const linkIsInternal = (linkElement) => {
  const isRelativeHref = linkElement.matches("a:not([href*='://'])");
  const isSameSiteHref = linkElement.matches(`a[href*='${window.location.host}']`);

  return isRelativeHref || isSameSiteHref;
};

const eventUsesControlKey = (event) => {
  const {
    altKey, ctrlKey, metaKey, shiftKey,
  } = event;

  return !!(metaKey || altKey || ctrlKey || shiftKey);
};

const eventPreventsDefault = (event) => {
  const { defaultPrevented } = event;

  return defaultPrevented;
};

const eventIsNotRightClick = (event) => {
  const { button } = event;

  return button !== undefined && button !== 0;
};

const eventOpensNewTab = (event) => {
  const { target } = event;

  if (!target || !target.getAttribute) return false;

  const linkTarget = target.getAttribute('target');

  return /\b_blank\b/i.test(linkTarget);
};

const urlIsMailto = (url) => url.protocol === 'mailto:';

const urlIsTelephoneNumber = (url) => url.protocol === 'tel:';

const urlPointsToAFile = (url) => {
  const urlExtension = url.pathname.split(/[#?]/)[0].split('.').pop().trim();
  const excludedExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif'];

  return excludedExtensions.includes(urlExtension);
};

const stripTrailingSlash = (pathname) => {
  const hasTrailingSlash = pathname[pathname.length - 1] === '/';

  if (hasTrailingSlash) return pathname.slice(0, -1);

  return pathname;
};

const linkPointsToCurrentPath = (url) => {
  if (!url.pathname) return false;

  const linkPath = stripTrailingSlash(url.pathname);
  const currentPath = stripTrailingSlash(window.location.pathname);

  return linkPath === currentPath;
};

// Vue router can handle this type of link but it is inferior to native functionality
// in a few ways. It is slower and it won't scroll again if the hash is part of current
//  route already.
const linkPointsToAnAnchorOnThisPage = (url) => linkPointsToCurrentPath(url) && !!url.hash;

const eventShouldBeHandled = (event, linkElement) => {
  // some sanity checks taken from vue-router:
  // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
  const { target } = event;

  if (!targetIsALink(target)) return false;

  const url = new URL(target.href);

  return linkIsInternal(linkElement)
    && !eventUsesControlKey(event)
    && !eventPreventsDefault(event)
    && !eventIsNotRightClick(event)
    && !eventOpensNewTab(event)
    && !urlIsMailto(url)
    && !urlPointsToAFile(url)
    && !linkPointsToAnAnchorOnThisPage(url)
    && !urlIsTelephoneNumber(url);
};

export default {
  methods: {
    /**
     * Handle same-site anchor tag clicks with vue-router to prevent full page reloads
     * Initially sourced from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
     * and then expanded for HU-specific use cases
     */
    handleLinkClick(event) {
      const linkElement = nearestLinkToTarget(event.target);

      if (!eventShouldBeHandled(event, linkElement)) return;

      const url = new URL(linkElement.href);
      const query = url.search ? Object.fromEntries(url.searchParams) : null;

      const to = {
        path: url.pathname,
        hash: url.hash,
        query,
      };

      event.preventDefault();
      this.$router.push(to);
    },
  },
};
